<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col cols="12">
            <h3>{{$_strings.p2pLending.PAYMENT_INFO}}</h3>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <h4>PT.SIGAP</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <span>Nama Bank</span>
          </v-col>
          <v-col cols="12" sm="6">
            <span>BCA - Bank Central Asia</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <span>A/C</span>
          </v-col>
          <v-col cols="12" sm="6">
            <span>54393453</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <span>A/N</span>
          </v-col>
          <v-col cols="12" sm="6">
            <span>PT.SIGAP SEJAHTERA</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="center"
          class="ma-0"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CLOSE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
  },
};
</script>
