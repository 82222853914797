<template>
  <v-container fluid>
    <v-sheet rounded class="pt-4 pl-1">
      <section class="section-1 pl-4">
        <h4>{{$_strings.p2pLending.DETAIL_P2P_LENDING}}</h4>
        <v-row class="mt-2">
          <v-col cols="6">
            <v-skeleton-loader
              v-if="isLoadingStatus"
              class="px-2"
              type="heading">
            </v-skeleton-loader>
            <v-icon v-else-if="errorGetStatus" @click="fetchList">
              mdi-reload
            </v-icon>
            <v-chip
              v-else-if="statusP2pLending && !isLoadingStatus"
              :color="getColorByStatus(statusP2pLending).background"
              :class="getColorByStatus(statusP2pLending).textColor"
              label
              class="text-capitalize"
            >
              {{statusP2pLending}}
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.NO_P2P_LENDING}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4" cols="12" sm="5">
            <span>{{$route.query.documentNo || '-'}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4 d-none" cols="auto">
            <v-btn
              color="primary"
              small
              block
              class="white--text"
              @click="$refs.dialogPaymentInfo.dialog = true"
            >
              {{$_strings.p2pLending.PAYMENT_INFO}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.CREATED_AT}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span>{{$route.query.createdAt ? dateFormat($route.query.createdAt) : '-'}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.TOTAL_INVOICE}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span>{{($route.query.totalAmount && formatCurrency($route.query.totalAmount)) || '-'}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
            <span>{{$_strings.p2pLending.TOTAL_P2P_LENDING}}</span>
          </v-col>
          <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
            <span class="font-weight-bold">{{($route.query.totalNett && formatCurrency($route.query.totalNett)) || '-'}}</span>
          </v-col>
        </v-row>
      </section>
      <section v-if="isLoading">
        <v-row v-for="skelteon in countSkeleton" :key="skelteon">
          <v-col>
            <v-skeleton-loader
              class="px-2"
              height="50"
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </section>
      <section v-else class="section-2">
        <v-row justify="center" class="pa-2">
          <v-expansion-panels
            multiple
            accordion
            flat
            v-model="expansionPanel"
          >
            <v-expansion-panel
              v-for="(item,index) in itemsInvoice"
              :key="index"
              @click="!('documents' in item) ? fetchDocumentDetail(item) : true"
            >
              <v-expansion-panel-header class="grey lighten-5">
                <v-row
                  justify="space-between"
                  align="center"
                >
                  <v-col>
                    <h4>Invoice {{index + 1}}</h4>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end">
                    <v-chip
                      :color="getColorByStatus(item.lendingStatus).background"
                      :class="getColorByStatus(item.lendingStatus).textColor"
                      label
                      class="text-capitalize"
                    >
                      {{formatStatus(item.lendingStatus)}}
                    </v-chip>
                    <v-chip
                      v-if="item.paymentStatus"
                      :color="getColorByStatus(item.paymentStatus).background"
                      :class="getColorByStatus(item.paymentStatus).textColor"
                      class="ml-2 text-capitalize"
                      label
                    >
                      {{item.paymentStatus}}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-divider></v-divider>
              <v-expansion-panel-content
                style="border: 1px solid #DDDD;"
              >
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.NO_INVOICE}}</span>
                  </v-col>
                  <v-col cols="8" sm="6" md="6">
                    <v-text-field
                      v-model="item.invoiceNo"
                      outlined
                      dense
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.PERIOD_ORDER}}</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="9">
                    <p class="pa-0 ma-0">
                      <span class="font-weight-bold">{{differenceMonth(item.periodStart, item.periodEnd)}} </span>
                      <span>{{dateFormat(item.periodStart)}} s/d {{dateFormat(item.periodEnd)}}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.TOTAL_BILL}}</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="9">
                    <span>{{formatCurrency(item.totalInvoice)}}</span>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.INVOICE_DATE}}</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="9">
                    <span>{{dateFormat(item.invoiceDate)}}</span>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.RECEIVED_INVOICE_DATE}}</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="9">
                    <span>{{dateFormat(item.receivedDate)}}</span>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.TOP}}</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="9">
                    <span>{{item.paymentTerm}}</span>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-4"></v-divider>
                <v-row align="center" class="pt-4">
                  <v-col cols="12" sm="4" md="3">
                    <span>{{$_strings.p2pLending.TOP_P2P_LENDING}}</span>
                  </v-col>
                  <v-col cols="12" sm="auto">
                    <v-select
                      hide-details
                      height="10"
                      :items="[
                        {
                          text: item.p2pTopDays,
                          value: item.p2pTopDays,
                        }
                      ]"
                      v-model="item.p2pTopDays"
                      class="caption"
                      placeholder="Pilih"
                      outlined
                      dense
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col cols="12">
                    <h4>{{$_strings.p2pLending.CALCULATOR}}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="tableHeaderCalculator"
                      :items="itemsCalculator(item)"
                      class="elevation-1 table"
                      item-key="index"
                      hide-default-footer
                      :server-items-length="100"
                    ></v-data-table>
                  </v-col>
                </v-row>
                <v-row class="mt-4">
                  <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
                    <p class="mb-0">{{$_strings.p2pLending.DUE_DATE}}</p>
                    <span>{{$_strings.p2pLending.INITIAL_INVOICE}}</span>
                  </v-col>
                  <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
                    <span>
                      {{dateFormat(item.invoiceDueDate)}}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0 pb-sm-4" cols="12" sm="4" md="3">
                    <p class="mb-0">{{$_strings.p2pLending.DUE_DATE}}</p>
                    <span>{{$_strings.p2pLending.INVOICE_P2P_LENDING}}</span>
                  </v-col>
                  <v-col class="pt-0 pt-sm-4" cols="12" sm="8" md="9">
                    <span class="font-weight-bold">
                      {{dateFormat(item.p2pDueDate)}}
                    </span>
                  </v-col>
                </v-row>
                <v-divider class="my-4" v-if="item.documents && item.documents.length"></v-divider>
                <v-row class="mt-5" v-if="item.documents && item.documents.length">
                  <v-col cols="12">
                    <h4>{{$_strings.p2pLending.DOCUMENT_SUPPORT}}</h4>
                  </v-col>
                </v-row>
                <v-row v-if="item.isLoadingDocuments" class="mt-5">
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="heading"
                      width="800"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" sm="3" v-for="docImage in countSkeleton" :key="docImage">
                    <v-skeleton-loader
                      type="image"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row v-else-if="item.isErrorGetDocuments" class="mt-5">
                  <v-col cols="12">
                    <h4 class="text-center red--text">{{$_strings.p2pLending.ERROR_MESSAGE_DOCUMENTS}}</h4>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-icon @click="fetchDocumentDetail(item)">
                      mdi-reload
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row v-else class="mt-5">
                  <v-col cols="12" sm="3" v-for="(doc, docIndex) in item.documents" :key="docIndex">
                    <div class="img-box">
                      <div
                        class="d-flex justify-center"
                      >
                        <v-icon
                          size="120"
                          @click="openDocument(doc.url)"
                        >
                          mdi-file
                        </v-icon>
                      </div>
                    </div>
                    <p class="text-center my-4 font-weight-bold">
                      {{doc.documentType.replaceAll('_', ' ')}} {{item.documents.length > 1 ? docIndex + 1 : ''}}
                    </p>
                  </v-col>
                </v-row>
                <v-form ref="form">
                  <section
                    v-if="!(item.lendingStatus === 'NEED_APPROVAL' && actionPath === 'detail')"
                    id="status"
                  >
                    <v-divider class="my-4"></v-divider>
                    <v-row
                      v-if="item.lendingStatus === 'NEED_APPROVAL'"
                      justify="end"
                    >
                      <v-col cols="auto" class="pb-0">
                        <p class="font-italic red--text pb-0">*Wajib dilengkapi</p>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="space-between"
                      class="mt-5"
                      :class="item.lendingStatus === 'NEED_APPROVAL' ? 'grey lighten-5' : ''"
                    >
                      <v-col cols="12" md="6">
                        <h4>
                          {{$_strings.p2pLending.P2P_LENDING_APPROVAL}}
                          <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                        </h4>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-select
                          height="10"
                          :rules="item.lendingStatus === 'NEED_APPROVAL' ? [v => !!v || $_strings.messages.validation.REQUIRED($_strings.p2pLending.P2P_LENDING_APPROVAL)] : []"
                          v-model="item.newStatus"
                          :items="itemActions"
                          class="caption"
                          :disabled="!(item.lendingStatus === 'NEED_APPROVAL')"
                          placeholder="Aksi"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      :class="item.lendingStatus === 'NEED_APPROVAL' ? 'grey lighten-5' : ''"
                    >
                      <v-col cols="12" md="6">
                        <h4>{{$_strings.p2pLending.NOTE}}
                          <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                        </h4>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          :rules="item.lendingStatus === 'NEED_APPROVAL' ? [(v) => rulesNotes(v, true, 255, $_strings.p2pLending.NOTE)] : []"
                          v-model="item.lendingNotes"
                          outlined
                          dense
                          class="grey--text"
                          :disabled="!(item.lendingStatus === 'NEED_APPROVAL')"
                          :counter="255"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </section>
                  <section
                    id="payment"
                    v-if="
                      (!(
                        item.lendingStatus === 'APPROVED'
                        && actionPath === 'detail'
                      )
                        && item.lendingStatus !== 'NEED_APPROVAL'
                        && item.lendingStatus !== 'REJECTED')
                        || (
                          item.paymentStatus
                          && item.paymentStatus.toLowerCase() === 'sudah dibayar'
                        )"
                  >
                    <v-divider class="my-4"></v-divider>
                    <v-row justify="space-between">
                      <v-col cols="12" sm="6">
                        <h4>{{$_strings.p2pLending.STATUS_PAYMENT}}</h4>
                      </v-col>
                      <v-col cols="12" sm="auto">
                        <span class="font-italic">{{item.paymentStatus || '-'}}</span>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="item.paymentStatus
                        && item.paymentStatus.toLowerCase() === 'belum dibayar'
                        && actionPath === 'edit'"
                    >
                      <v-col cols="12" sm="3">
                        <p>{{$_strings.p2pLending.UPLOAD_PROOF_OF_PAYMENT}}
                          <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                        </p>
                      </v-col>
                      <v-col cols="auto">
                        <v-file-input
                          class="file_input d-none"
                          height="20"
                          :clearable="false"
                          @change="($event) => setFileInput($event, item)"
                          :ref="`${index}`"
                          type="file"
                          accept="image/*"
                          outlined
                          dense
                          placeholder="Pilih File"
                          prepend-icon=""
                        >
                        </v-file-input>
                        <v-btn
                          color="blue-grey"
                          small
                          block
                          class="white--text"
                          @click="$refs[`${index}`][0].$refs.input.click()"
                        >
                          {{$_strings.p2pLending.ATTACHMENT_DOCUMENT}}
                          <v-icon
                            right
                            dark
                          >
                            mdi-cloud-upload
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="3" class="pb-0">
                        <p>{{$_strings.p2pLending.PAYMENT_DATE}}
                          <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                        </p>
                      </v-col>
                      <v-col cols="auto" class="pb-0">
                        <v-menu
                          ref="menuFromDate"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              outlined
                              class="caption"
                              :value="item.paymentDate && dayjs(item.paymentDate).format('DD-MM-YYYY')"
                              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.p2pLending.PAYMENT_DATE)]"
                              placeholder="DD/MM/YYYY"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              :disabled="
                                !(item.lendingStatus === 'APPROVED'
                                  && actionPath === 'edit'
                                  && (item.paymentStatus
                                    && item.paymentStatus.toLowerCase() === 'belum dibayar')
                                )"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.paymentDate"
                            no-title
                            :disabled="
                              !(item.lendingStatus === 'APPROVED'
                                && actionPath === 'edit'
                                && (item.paymentStatus
                                  && item.paymentStatus.toLowerCase() === 'belum dibayar')
                              )"
                            locale="id"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <p>{{$_strings.p2pLending.PROOF_OF_PAYMENT}}
                          <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                        </p>
                      </v-col>
                      <v-col v-if="!item.paymentAttachment" class="auto">
                        <p class="font-italic">*Belum ada file attachment*</p>
                      </v-col>
                      <v-col v-else cols="auto" >
                        <v-icon>mdi-attachment</v-icon>
                        <v-btn
                          text
                          color="primary"
                          @click="openDocument(item.paymentAttachment, item.fileType)"
                        >
                          Bukti Pembayaran Invoice {{ index + 1}}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <h4>{{$_strings.p2pLending.NOTE}}</h4>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="item.paymentNotes"
                          outlined
                          dense
                          class="grey--text"
                          :rules="[(v) => rulesNotes(v, true, 255, $_strings.p2pLending.NOTE)]"
                          :disabled="
                            !(item.lendingStatus === 'APPROVED'
                              && actionPath === 'edit'
                              && (item.paymentStatus
                                && item.paymentStatus.toLowerCase() === 'belum dibayar')
                            )"
                          :counter="255"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </section>
                </v-form>
                <v-row justify="end">
                  <v-col cols="auto">
                    <v-btn
                      v-if="
                        actionPath !== 'detail'
                          && (
                            (item.paymentStatus && item.paymentStatus.toLowerCase() === 'belum dibayar')
                            || item.lendingStatus === 'NEED_APPROVAL'
                          )
                          && item.lendingStatus !== 'REJECTED'
                      "
                      color="primary"
                      small
                      block
                      class="white--text"
                      @click="submit(index, item)"
                    >
                      {{$_strings.p2pLending.SUBMIT}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </section>
      <section class="section-3" v-if="!isLoading">
        <v-row justify="end">
          <v-col cols="12" sm="3" md="2">
            <v-btn
              color="primary"
              outlined
              class="white--text"
              small
              block
              @click="$router.go(-1)"
            >
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </v-sheet>
    <dialog-view-image
      :visible="dialogViewImage"
      @setDialog="setDialogFile"
      :imageUrl="imageSelected"
    />
    <dialog-view-pdf
      :visible="dialogViewPdf"
      @setDialog="setDialogFile"
      :urlPdf="urlPdfSelected"
    />
    <DialogPaymentInfo
      ref="dialogPaymentInfo"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DialogPaymentInfo from './Dialog/PaymentInfo.vue';
import { rulesNotes, dateFormat } from '../../helper/commonHelpers';

let intervalCountSkeleton;

export default {
  name: 'create-p2p-lending',
  components: {
    DialogPaymentInfo,
  },
  mounted() {
    this.fetchList();
    this.fetchDetail();
  },
  data() {
    return {
      errorGetStatus: false,
      isLoadingStatus: false,
      statusP2pLending: '',
      countSkeleton: 1,
      isLoading: false,
      expansionPanel: [],
      itemsInvoice: [],
      itemActions: [
        {
          text: 'Setuju',
          value: 'APPROVED',
        },
        {
          text: 'Tolak',
          value: 'REJECTED',
        },
      ],
      tableHeaderCalculator: [
        {
          text: this.$_strings.p2pLending.TOTAL_INVOICE,
          value: 'totalInvoice',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.ADMIN_FEES,
          value: 'adminRate',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.INTEREST_COST,
          value: 'interestRate',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.INITIAL_TOP,
          value: 'paymentTerm',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.TOP_P2P_LENDING_2,
          value: 'p2pTopDays',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.p2pLending.TOTAL_PAYMENT_TO_3PL,
          value: 'totalP2pLending',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
      ],
      dialogViewImage: false,
      imageSelected: '',
      dialogViewPdf: false,
      urlPdfSelected: '',
    };
  },
  computed: {
    actionPath() {
      // detail, edit
      const [, , , path] = this.$route.path.split('/');
      return path;
    },
  },
  methods: {
    dateFormat,
    dayjs,
    setCountSkeleton() {
      intervalCountSkeleton = setInterval(() => {
        if (this.countSkeleton > 3) {
          this.countSkeleton = 1;
          return;
        }
        this.countSkeleton += 1;
      }, 1000);
    },
    rulesNotes,
    formatCurrency(amount) {
      return (amount && `Rp. ${amount.toLocaleString('id-ID')}`) || 0;
    },
    differenceMonth(startDate, endDate) {
      if (!startDate || !endDate) return '-';
      const date1 = dayjs(endDate);
      return `${date1.diff(startDate, 'month')} Bulan`;
    },
    itemsCalculator(item) {
      const {
        adminRate,
        interestRate,
        paymentTerm,
        p2pTopDays,
        totalP2pLending,
        totalInvoice,
      } = item;
      return [{
        totalInvoice: this.formatCurrency(totalInvoice),
        adminRate: `${adminRate}%`,
        interestRate: `${interestRate}%`,
        paymentTerm,
        p2pTopDays,
        totalP2pLending: this.formatCurrency(totalP2pLending),
      }];
    },
    getColorByStatus(status) {
      switch (status.toLowerCase()) {
        case 'need_approval':
        case 'menunggu persetujuan':
        case 'belum dibayar':
          return {
            background: 'yellow',
            textColor: 'black--text',
          };
        case 'approved':
        case 'disetujui':
          return {
            background: 'green',
            textColor: 'white--text',
          };
        case 'ditolak':
        case 'rejected':
          return {
            background: 'red',
            textColor: 'white--text',
          };
        default:
          return {
            background: 'primary',
            textColor: 'white--text',
          };
      }
    },
    formatStatus(status) {
      return this.$_strings.p2pLending.LIST_STATUS_P2P_LENDING[status];
    },
    async fetchList() {
      try {
        this.isLoadingStatus = true;
        this.errorGetStatus = false;
        const { id } = this.$route.params;
        const {
          dateFrom,
          dateTo,
          page,
          size,
          sort,
        } = this.$route.query;
        const filters = {
          page,
          size,
          dateFrom,
          dateTo,
          sort,
        };
        const result = await this.$_services.p2pLending.fetchP2pLendingList(filters);
        const findListById = result.data.contents.find((res) => +res.id === +id);
        if (findListById) this.statusP2pLending = findListById.status.toLowerCase();
      } catch {
        this.errorGetStatus = true;
      } finally {
        this.isLoadingStatus = false;
      }
    },
    async fetchDetail(indexPanel = null, documents = []) {
      try {
        this.setCountSkeleton();
        this.isLoading = true;
        const { id } = this.$route.params;
        const result = await this.$_services.p2pLending.fetchP2pLendingDetail(id);
        this.itemsInvoice = result.data.map((data) => ({
          ...data,
          newStatus: data.lendingStatus.toLowerCase() === 'need_approval' ? null : data.lendingStatus,
        }));
        if (typeof indexPanel === 'number') {
          this.expansionPanel = [indexPanel];
          this.itemsInvoice[indexPanel].documents = documents;
        }
      } finally {
        clearInterval(intervalCountSkeleton);
        this.isLoading = false;
      }
    },
    async fetchDocumentDetail(item) {
      try {
        this.setCountSkeleton();
        this.$set(item, 'isLoadingDocuments', true);
        const { id } = this.$route.params;
        const result = await this.$_services.p2pLending.fetchP2pLendingDocument(id, item.id);
        const documents = [];
        result.data.forEach((doc) => {
          documents.push(...doc.documents.map((file) => ({
            ...file,
            documentType: doc.documentType,
          })));
        });
        this.$set(item, 'documents', documents);
        this.$delete(item, 'isErrorGetDocuments');
      } catch {
        this.$set(item, 'documents', []);
        this.$set(item, 'isErrorGetDocuments', true);
      } finally {
        clearInterval(intervalCountSkeleton);
        this.$delete(item, 'isLoadingDocuments', true);
      }
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf', 'bmp'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file maksimal ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    setFileInput(file, item) {
      const { name } = file;
      const fileType = name.split('.').pop().toLowerCase();
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.onloadend = (e) => {
        this.$set(item, 'paymentAttachment', e.target.result);
        this.$set(item, 'fileType', fileType);
      };
    },
    openDocument(url, fileType = '') {
      const imageExtension = ['jpg', 'jpeg', 'png', 'bmp'];
      if (imageExtension.includes(fileType.toLowerCase())) {
        this.dialogViewImage = true;
        this.imageSelected = url;
        return;
      }
      if (fileType.toLowerCase() === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = url;
        return;
      }
      window.open(url);
    },
    setDialogFile(dialog) {
      this[dialog] = !this[dialog];
    },
    async submit(index, item) {
      const { id } = this.$route.params;
      const {
        id: detailId,
        invoiceNo,
        totalP2pLending,
        lendingNotes: notes,
        paymentAttachment,
        paymentDate,
        paymentNotes,
        newStatus,
        documents,
      } = item;
      const valid = this.$refs.form[index].validate();
      if (!valid) return;
      if (this.statusP2pLending === 'menunggu persetujuan') {
        this.$dialog.confirm({
          text: 'Apakah Anda Yakin?',
          title: this.$_strings.common.WARNING,
          persistent: true,
          actions: {
            false: this.$_strings.common.NO,
            true: this.$_strings.common.YES,
          },
        }).then(async (userRes) => {
          if (!userRes) return;
          try {
            this.$root.$loading.show();
            await this.$_services.p2pLending.updateP2pLendingStatus(id, detailId, newStatus, notes);
            this.$dialog.notify.success('Berhasil');
            this.fetchList();
            this.fetchDetail(index, documents);
          } finally {
            this.$root.$loading.hide();
          }
        });
      }
      if (this.statusP2pLending === 'disetujui') {
        if (!paymentAttachment) return this.$dialog.notify.error('Silahkan lampirkan bukti pembayaran!');
        this.$dialog.confirm({
          text: `${this.$_strings.p2pLending.UPDATE_PAYMENT_CONFIRM_MESSAGE} No Invoice "${invoiceNo}" Total ${this.formatCurrency(totalP2pLending)} ?`,
          title: this.$_strings.common.WARNING,
          persistent: true,
          actions: {
            false: this.$_strings.common.NO,
            true: this.$_strings.common.YES,
          },
        }).then(async (userRes) => {
          if (!userRes) return;
          try {
            this.$root.$loading.show();
            const formPayment = {
              paymentDate,
              encodedImages: paymentAttachment,
              paymentNotes,
            };
            await this.$_services.p2pLending.updateP2pLendingPayment(id, detailId, formPayment);
            this.$dialog.notify.success('Berhasil');
            this.fetchList();
            this.fetchDetail(index, documents);
          } finally {
            this.$root.$loading.hide();
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .img-box{
    margin-bottom: 2px;
    display: block;
    width: 100%;
    border: 1px solid rgb(189, 183, 183);
    height: 120px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
</style>
