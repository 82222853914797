import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=83c1e1fa&scoped=true&"
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"
import style0 from "./Create.vue?vue&type=style&index=0&id=83c1e1fa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83c1e1fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VChip,VCol,VContainer,VDataTable,VDatePicker,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VFileInput,VForm,VIcon,VMenu,VRow,VSelect,VSheet,VSkeletonLoader,VTextField,VTextarea})
